import React from 'react';
import { Box, TextField, Grid, Typography, Button } from '@mui/material';
import swal from "sweetalert";
import { PuffLoader } from "react-spinners";
import { css } from "@emotion/react";
import {createCediOrder} from "../../utilityFunctions/WarehouseUtil";
import Colors from "../../components/ui/Colors";
import { useHistory } from "react-router-dom";


const cssLoader = css`
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

const CediOrderRequest ={};

export default function CediOrderCreate(props) {

    const {loginUser} = props;

    const [loading, setLoading] = React.useState(false);

    const history = useHistory();

    const[transportPlate, setTransportPlate] = React.useState("");

    const transportPlateChange = (e) => {
        setTransportPlate(e.target.value);
        CediOrderRequest.transportPlate = e.target.value;
    };

    const submitCediOrder = async () => {
        setLoading(true);
        const response = await createCediOrder(CediOrderRequest, loginUser.jwtToken);
        setLoading(false);

        if(!response){
            swal({
                title: "Orden no creada",
                icon: "error",
                button: "OK",
            });
            return;
        }

        swal({
            title: "Orden Creada",
            icon: "success",
            button: "OK",
        }).then(() => {
            history.push({
                pathname: "/warehouse/cedi-batches",
                state: {
                    cediOrder: response,
                }
            });   
        });
    };


    return (
        <Box sx={{display: "flex", justifyContent: "center", width: "100%", height: "100%"}}>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "95%",
                    height: "90%",
                    textAlign: "center",
                    marginTop: {xs: "1.3rem", sm: 0},
                }}
            >
                {loading ? <PuffLoader size={100} color={Colors.secondary} css={cssLoader} /> :
                    <Grid container spacing={2} sx={{display: "flex", "justifyContent": "center"}}>
                        <Grid item xs={12} sx={{display: "flex", justifyContent: "center",}}>
                            <TextField
                                variant="standard"
                                fullWidth={true}
                                sx={{width: {xs: "90%", sm: "80%", md: "40%"}}}
                                label={<Typography variant="body2">Placa Transporte</Typography>}
                                value={transportPlate}
                                onChange={(e)=> transportPlateChange(e)}
                                autoComplete="off"
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                            <Button onClick={submitCediOrder} variant="contained" sx={{backgroundColor: Colors.primary, marginTop: "2rem",}}>
                                Crear
                            </Button>
                        </Grid>
                    </Grid>
                }
            </Box>
        </Box>
    )
}
