import React from 'react';
import { Box, TextField, Grid, Typography, Button, Divider } from '@mui/material';
import swal from "sweetalert";
import { PuffLoader } from "react-spinners";
import { css } from "@emotion/react";
import {addBatchToCediOrder, getProductBatch} from "../../utilityFunctions/WarehouseUtil";
import Colors from "../../components/ui/Colors";
import { useHistory } from "react-router-dom";

const dateOptions = {
    // weekday: "short",
    day: "numeric",
    month: "numeric",
    year: "numeric",
};

const cssLoader = css`
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

const addBatchRequest ={};

const getBatchRequest={};

export default function CediOrderAddBatch(props) {

    const {loginUser} = props;

    const [loading, setLoading] = React.useState(false);

    const history = useHistory();

    const cediOrder = props?.location?.state?.cediOrder;

    const[batchId, setBatchId] = React.useState("");


    const batchIdChange = (e) => {
        setBatchId(e.target.value);
        addBatchRequest.batchId = e.target.value;
        getBatchRequest.id = e.target.value;
    }

    const[description, setDescription] = React.useState("");
    const[amount, setAmount] = React.useState("");
    const[batchLevel, setBatchLevel] = React.useState("");
    const[batchCode, setBatchCode] = React.useState("");
    const[expirationDate, setExpirationDate] = React.useState("");


    const getBatchSubmit = async() => {
        
        setLoading(true);
        const productResponse = await getProductBatch(getBatchRequest, loginUser.jwtToken);
        setLoading(false);

        if(!productResponse){
            swal({
                title: "Código errado",
                text: "El código ingresado es incorrecto. Por favor verificar",
                icon: "error",
                buttons: "OK"
            });
            return;
        }

        setDescription(productResponse.warehouseProduct.description);
        setAmount(productResponse.productAmount);
        setBatchLevel(productResponse.level);
        setBatchCode(productResponse.batchCode);
        setExpirationDate(productResponse.expirationDate)


    };

    const submitAddBatch = async () => {

        if(!cediOrder){
            swal({
                title: "Orden no seleccionada",
                icon: "error",
                button: "OK",
            });
            return;
        };

        addBatchRequest.cediOrderId = cediOrder.id;

        setLoading(true);
        const response = await addBatchToCediOrder(addBatchRequest, loginUser.jwtToken);
        setLoading(false);

        addBatchRequest.batchId = null;
        setBatchId("");

        if(!response){
            swal({
                title: "Estiba no agregada",
                icon: "error",
                button: "OK",
            });
            return;
        }

        swal({
            title: "Estiba Agregada",
            icon: "success",
            button: "OK",
        });

        setDescription("");
        setAmount("");
        setBatchLevel("");
        setBatchCode("");
        setBatchId("");
        setExpirationDate("");
    };

    const goToOrderList = () => {
        history.push({
            pathname: "/warehouse/cedi-orders-list",
        });
    }; 

    return (
        <Box sx={{display: "flex", justifyContent: "center", width: "100%", height: "100%"}}>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "95%",
                    height: "90%",
                    textAlign: "center",
                    marginTop: {xs: "1.3rem", sm: 0},
                }}
            >
                {loading ? <PuffLoader size={100} color={Colors.secondary} css={cssLoader} /> :
                    <Grid container spacing={2} sx={{display: "flex", justifyContent: "center",}}>
                        {description && amount && batchLevel && batchCode && expirationDate &&
                        <Grid item xs={12} sx={{display: "flex", justifyContent: "center", }}>
                            <Grid container sx={{display: "flex", justifyContent: "center", alignItems: "start",  width: {xs: "90%", sm: "90%", md: "50%"}, height: "75%", }}>
                                <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "start", alignItems: "center",  marginLeft: "10%", marginTop: {xs: "2rem", sm: "2rem", md: "4rem"}}}>
                                    <Typography sx={{color: "gray", fontSize: "0.6rem"}}>Número Estiba</Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "start", alignItems: "center", marginLeft: "10%"}}>
                                    <Typography variant="body1">{`${batchId}`}</Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} sx={{marginLeft: "10%"}}>
                                    <Divider sx={{width: "70%"}} />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "start", alignItems: "center", marginLeft: "10%"}}>
                                    <Typography sx={{color: "gray", fontSize: "0.6rem"}}>Descripción</Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "start", alignItems: "center", marginLeft: "10%"}}>
                                    <Typography variant="body1">{`${description}`}</Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} sx={{marginBottom: "0.6rem", marginLeft: "10%"}}>
                                    <Divider sx={{width: "70%"}} />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "start", alignItems: "center", marginLeft: "10%"}}>
                                    <Typography sx={{color: "gray", fontSize: "0.6rem"}}>Niveles</Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "start", alignItems: "center", marginLeft: "10%"}}>
                                    <Typography variant="body1">{`${batchLevel}`}</Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} sx={{marginBottom: "0.6rem", marginLeft: "10%"}}>
                                    <Divider sx={{width: "70%"}} />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "start", alignItems: "center", marginLeft: "10%"}}>
                                    <Typography sx={{color: "gray", fontSize: "0.6rem"}}>Cantidad</Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "start", alignItems: "center", marginLeft: "10%"}}>
                                    <Typography variant="body2">{`${new Intl.NumberFormat('es-CO').format(amount)}`}</Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} sx={{marginLeft: "10%"}}>
                                    <Divider sx={{width: "70%"}} />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "start", alignItems: "center", marginLeft: "10%"}}>
                                    <Typography sx={{color: "gray", fontSize: "0.6rem"}}>Lote</Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "start", alignItems: "center", marginLeft: "10%"}}>
                                    <Typography variant="body1">{`${batchCode}`}</Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} sx={{marginBottom: "0.6rem", marginLeft: "10%"}}>
                                    <Divider sx={{width: "70%"}} />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "start", alignItems: "center", marginLeft: "10%"}}>
                                    <Typography sx={{color: "gray", fontSize: "0.6rem"}}>Fecha Vencimiento</Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "start", alignItems: "center", marginLeft: "10%"}}>
                                    <Typography variant="body1">{`${new Intl.DateTimeFormat('es-CO', dateOptions).format(new Date(expirationDate))}`}</Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} sx={{marginBottom: "0.6rem", marginLeft: "10%"}}>
                                    <Divider sx={{width: "70%"}} />
                                </Grid>
                                <Grid item xs={12} sx={{display: "flex", justifyContent: "center"}}>
                                    <Button onClick={submitAddBatch} variant="contained" sx={{marginLeft: "1rem", backgroundColor: Colors.primary, marginTop: "2rem",}}>
                                        Agregar
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                        }      
                        <Grid item xs={12} sx={{display: "flex", justifyContent: "center"}}>
                            <TextField
                                variant="standard"
                                fullWidth={true}
                                sx={{width: {xs: "90%", sm: "80%", md: "20%"}}}
                                label={<Typography variant="body2">Código Estiba</Typography>}
                                value={batchId}
                                onChange={(e)=> batchIdChange(e)}
                                autoComplete="off"
                            />
                        </Grid>
                        <Grid item xs={6} sx={{display: "flex", justifyContent: "end", marginTop: "1rem", marginBottom: "1rem"}}>
                            <Button onClick={goToOrderList} variant="contained" sx={{marginRight: "1rem", backgroundColor: Colors.primary,}}>
                                Atrás
                            </Button>
                        </Grid>
                        <Grid item xs={6} sx={{display: "flex", justifyContent: "start", marginTop: "1rem", marginBottom: "1rem"}}>
                            <Button onClick={getBatchSubmit} variant="contained" sx={{marginLeft: "1rem", backgroundColor: Colors.primary,}}>
                                Buscar
                            </Button>
                        </Grid>
                    </Grid>
                }
            </Box>
        </Box>
    )
}
