import React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import {Typography, Select, MenuItem, Button} from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import Dialog from '@mui/material/Dialog';
import Colors from "../../components/ui/Colors";
import { PuffLoader } from "react-spinners";
import { css } from "@emotion/react";
import FeedOutlinedIcon from '@mui/icons-material/FeedOutlined';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import swal from 'sweetalert';
import {closeCediOrder, getCediOrder, 
    getCediOrdersPage} from "../../utilityFunctions/WarehouseUtil";
import { useHistory} from "react-router-dom";

const cssLoader = css`
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

const dateOptions = {
    // weekday: "short",
    day: "numeric",
    month: "numeric",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
};

let selectedOrder;

const orderClosingRequest = {
    id: "",
};

let ordersArray;
let allOrders;

const pageRequest = {
    size: "",
    page: "",
};

const pageSize=100;

export default function CediOrdersList(props) {

    const {loginUser} = props;

    const history = useHistory();

    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {
        initialValues();
    }, []);

    const initialValues = async () => {

        pageRequest.page = 0;

        setLoading(true);
        const orderObjects = await getCediOrdersPage(pageRequest, loginUser.jwtToken);
        setLoading(false);

        if(orderObjects && orderObjects.length < pageSize){
            setDisableNextButton(true);
        }else{
            setDisableNextButton(false);
        }

        allOrders=orderObjects;

        ordersArray=orderObjects;

        renderOrderList();

        return orderObjects;
    };

    const [ordersList, setOrdersList] = React.useState("");

    const renderOrderList = async () => {

        if(!ordersArray){
            
            return;
        }

        ordersArray.sort((a, b) => {
            // Compare by 'status' first
            const statusComparison = b.status.localeCompare(a.status);
          
            // If statuses are different, return the status comparison result
            if (statusComparison !== 0) {
              return statusComparison;
            }
          
            // If statuses are the same, compare by 'date'
            return new Date(b.date) - new Date(a.date);
        });

        setOrdersList(
            <List sx={{width: "100%", marginTop: {xs: "3rem", sm: "2rem", md: "2rm"}}}>
                {ordersArray.map((order) => {
                    return(
                        <ListItem key={order.id} disablePadding divider sx={{marginBottom: "1.5rem"}}>
                            <ListItemButton onClick={() => openActionBox(order)}>
                                <Grid container spacing={1}>
                                    <Grid item xs={3} sm={3} md={3} sx={{display: "flex", justifyContent: "center"}}>
                                        <Typography variant="body2">{`${new Intl.DateTimeFormat('es-CO', dateOptions).format(new Date(order.date))}`}</Typography>
                                    </Grid>
                                    <Grid item xs={3} sm={3} md={3} sx={{display: "flex", justifyContent: "center"}}>
                                        <Typography variant="body2">{order.transportPlate}</Typography>
                                    </Grid>
                                    <Grid item xs={3} sm={3} md={3} sx={{display: "flex", justifyContent: "center"}}>
                                        <Typography variant="body2">{order.id}</Typography>
                                    </Grid>
                                    <Grid item xs={3} sm={3} md={3} sx={{display: "flex", justifyContent: "center"}}>
                                        <Typography variant="body2">{order.status === "open" ? "Abierta" : "Completada"}</Typography>
                                    </Grid>
                                </Grid>
                            </ListItemButton>
                        </ListItem>
                    )
                })}
            </List>
        );
    };

    const[actionBox, setActionsBox] = React.useState(false);

    const closeActionBox = () => {
        setActionsBox(false);
    };

    const openActionBox = (order) => {
        selectedOrder = order;
        orderClosingRequest.id = order.id;
        setActionsBox(true);
    };

    const completeOrder = async () => {
        closeActionBox();

        setLoading(true);
        const orderClosed = await closeCediOrder(orderClosingRequest, loginUser.jwtToken);
        setLoading(false);

        if(!orderClosed){
            swal({
                title: "Ocurrio un error",
                text: "Inténtalo de nuevo.",
                icon: "error",
                buttons: "OK"
            });
            return;
        }

        initialValues();

        swal({
            title: "Actualización exitosa",
            icon: "success",
            buttons: "OK"
        });
    };

    const selectOrder = async () => {

        const orderResponseRequest = {
            id: selectedOrder.id,
        };

        setLoading(true);
        const orderResponse = await getCediOrder(orderResponseRequest, loginUser.jwtToken);
        setLoading(false);

        if(!orderResponse){
            swal({
                title: "Ocurrio un error",
                text: "Inténtalo de nuevo.",
                icon: "error",
                buttons: "OK"
            });
            return;
        }

        history.push({
            pathname: "/warehouse/cedi-order",
            state: {
                order: orderResponse,
            }
        });
    };

    const addBatches = () => {
        history.push({
            pathname: "/warehouse/cedi-batches",
            state: {
                cediOrder: selectedOrder,
            }
        });
    };

    const removeBatches = () => {
        history.push({
            pathname: "/warehouse/cedi-remove-batches",
            state: {
                cediOrder: selectedOrder,
            }
        });
    };

    const goToCreateOrder = () => {
        history.push({
            pathname: "/warehouse/new-cedi-order",
        });
    };

    const[disableNextButton, setDisableNextButton] = React.useState(false);
    const [currentPage, setCurrentPage] = React.useState(1);

    const handlePageChange = async (newPage) => {
        setCurrentPage(newPage);
        pageRequest.page= newPage-1;

        console.log(pageRequest);

        setLoading(true);
        const receivedOrders = await getCediOrdersPage(pageRequest, loginUser.jwtToken);
        setLoading(false);

        if(!receivedOrders){
            return;
        }

        if(receivedOrders.length < pageSize){
            setDisableNextButton(true);
        }else{
            setDisableNextButton(false);
        }

        initialValues();
    };



    return (
        <Box sx={{display: "flex", justifyContent: "center", width: "100%", height: "100%"}}>
            <Box
                sx={{
                    display: "flex",
                    // flex: 1,
                    justifyContent: "center",
                    alignItems: {xs: "start", sm: "center"},
                    width: "90%",
                    height: "100%",
                    textAlign: "center",
                    overflowY: "scroll",
                }}
            >
            {loading ? <PuffLoader size={100} color={Colors.secondary} css={cssLoader} /> : 
            <>
                <Grid container>
                    <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "center", marginTop: "1rem", marginBottom: "1rem"}}>
                        <Button onClick={goToCreateOrder} variant="contained" sx={{backgroundColor: Colors.primary,}}>
                            Nueva Orden
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <Grid container sx={{marginTop: "1.5rem"}}>
                            <Grid  item xs={3} sm={3} md={3} sx={{display: "flex", justifyContent: "center"}}>
                                <Typography variant="body1" sx={{color: "black", fontWeight: "bold", textDecoration: 'underline',}}>Fecha</Typography>
                            </Grid>
                            <Grid  item xs={3} sm={3} md={3} sx={{display: "flex", justifyContent: "center"}}>
                                <Typography variant="body1" sx={{color: "black", fontWeight: "bold", textDecoration: 'underline',}}>Placa</Typography>
                            </Grid>
                            <Grid  item xs={3} sm={3} md={3} sx={{display: "flex", justifyContent: "center"}}>
                                <Typography variant="body1" sx={{color: "black", fontWeight: "bold", textDecoration: 'underline',}}>Código</Typography>
                            </Grid>
                            <Grid  item xs={3} sm={3} md={3} sx={{display: "flex", justifyContent: "center"}}>
                                <Typography variant="body1" sx={{color: "black", fontWeight: "bold", textDecoration: 'underline',}}>Estado</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        {ordersList}
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} sx={{ display: "flex", justifyContent: "center", marginTop: "2rem", marginBottom: "2rem" }}>

                        <Button
                            onClick={() => handlePageChange(currentPage - 1)}
                            variant="contained"
                            disabled={currentPage === 1}
                            sx={{ marginRight: "1rem" }}
                        >
                            Anterior
                        </Button>
                        <Typography>{currentPage}</Typography>
                        <Button
                            onClick={() => handlePageChange(currentPage + 1)}
                            variant="contained"
                            disabled={disableNextButton}
                            sx={{ marginLeft: "1rem" }}
                        >
                            Siguiente
                        </Button>
                    </Grid>
                </Grid>
                <Dialog open={actionBox} onClose={closeActionBox}
                    sx={{
                        "& .MuiDialog-container": {
                            "& .MuiPaper-root": {
                                width: {xs: "80%", sm: "40%", md: "30%"},
                            },
                        },
                    }}
                >
                    <List sx={{width: "100%",}}>
                        <ListItem disablePadding divider sx={{marginBottom: "0.2rem", marginTop: "0.5rem"}}>
                            <ListItemButton  onClick={selectOrder}>
                                <Grid container>
                                    <Grid item xs={2} sm={2} md={2} sx={{display: "flex", justifyContent: "center"}}>
                                        <FeedOutlinedIcon style={{fontSize: "2rem"}}/>
                                    </Grid>
                                    <Grid item xs={9} sm={9} md={9} sx={{display: "flex", justifyContent: "start"}}>
                                        <Typography variant="h6">Detalle</Typography>
                                    </Grid>
                                </Grid>
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding divider sx={{marginBottom: "0.2rem", marginTop: "0.5rem"}}>
                            <ListItemButton  onClick={addBatches}>
                                <Grid container>
                                    <Grid item xs={2} sm={2} md={2} sx={{display: "flex", justifyContent: "center"}}>
                                        <AddCircleOutlineIcon style={{fontSize: "2rem"}}/>
                                    </Grid>
                                    <Grid item xs={9} sm={9} md={9} sx={{display: "flex", justifyContent: "start"}}>
                                        <Typography variant="h6">Agregar Estibas</Typography>
                                    </Grid>
                                </Grid>
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding sx={{marginBottom: "0.2rem", marginTop: "0.5rem"}}>
                            <ListItemButton  onClick={removeBatches}>
                                <Grid container>
                                    <Grid item xs={2} sm={2} md={2} sx={{display: "flex", justifyContent: "center"}}>
                                        <RemoveCircleOutlineIcon style={{fontSize: "2rem"}}/>
                                    </Grid>
                                    <Grid item xs={9} sm={9} md={9} sx={{display: "flex", justifyContent: "start"}}>
                                        <Typography variant="h6">Remover Estibas</Typography>
                                    </Grid>
                                </Grid>
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding sx={{marginBottom: "0.5rem", marginTop: "0.2rem"}}>
                            <ListItemButton  onClick={completeOrder} sx={{textAlign: "center"}}>
                                <Grid container>
                                    <Grid item xs={2} sm={2} md={2} sx={{display: "flex", justifyContent: "center"}}>
                                        <CheckCircleOutlinedIcon style={{fontSize: "2rem"}}/>
                                    </Grid>
                                    <Grid item xs={9} sm={9} md={9} sx={{display: "flex", justifyContent: "start"}}>
                                        <Typography variant="h6">Cerrar Orden</Typography>
                                    </Grid>
                                </Grid>
                            </ListItemButton>
                        </ListItem>
                    </List>
                </Dialog>
            </>
            }
            </Box>
        </Box>
    )
}
