import {wupoServerUri} from '../Api';

const createWarehouseLabel = async(requestObject, jwtToken) => {
  

    // console.log(requestObject);
  
    const response = await fetch(`${wupoServerUri.devnet}/api/secure/newlabel`, {
        method: "POST",
        body: JSON.stringify(requestObject),
        credentials: 'include',
        headers: {
          "Authorization": `Bearer ${jwtToken}`,
          "Content-Type": "application/json; charset=UTF-8",
        },
    })
    .catch((error) => {
      // console.log("Error: " + error);
    });
  
    // console.log(response.status);
    if(response.status === 200 ){

      const qrResponse = await response.json();
        // console.log(qrResponse);

        const byteImage = Buffer.from(qrResponse.qrImageBytes, 'base64');
        qrResponse.qrImageBytes = new Blob([byteImage], {type: "application/octet-stream"});
        

        // const qrImg = new Blob([qrResponse], { type: 'image/png'});

        return qrResponse;
    }
  
    else {
      return null;
    }
};

const discardProductBatch = async(requestObject, jwtToken) => {
  

  // console.log(requestObject);

  const response = await fetch(`${wupoServerUri.devnet}/api/secure/discardlabel`, {
      method: "POST",
      body: JSON.stringify(requestObject),
      credentials: 'include',
      headers: {
        "Authorization": `Bearer ${jwtToken}`,
        "Content-Type": "application/json; charset=UTF-8",
      },
  })
  .catch((error) => {
    console.log("Error: " + error);
  });

  // console.log(response.status);
  if(response.status === 200 ){

    const discardBatch = await response.json();
      // console.log(discardBatch);

      return discardBatch;
  }

  else {
    return null;
  }
};

const getWarehouseCodes = async(jwtToken) => {
  

    // console.log(requestObject);
  
    const response = await fetch(`${wupoServerUri.devnet}/api/secure/warehouseCodes`, {
        method: "POST",
        // body: JSON.stringify(requestObject),
        credentials: 'include',
        headers: {
          "Authorization": `Bearer ${jwtToken}`,
        //   "Content-Type": "application/json; charset=UTF-8",
        },
    })
    .catch((error) => {
      console.log("Error: " + error);
    });
  
    // console.log(response.status);
    if(response.status === 200 ){
      const codes = await response.json();
      // console.log(codes);

  
      return codes;
    }
  
    else {
        return null;
    }
};

const getProductBatch = async(requestObject, jwtToken) => {
  
  // console.log(requestObject);

  const response = await fetch(`${wupoServerUri.devnet}/api/secure/productBatch`, {
      method: "POST",
      body: JSON.stringify(requestObject),
      credentials: 'include',
      headers: {
        "Authorization": `Bearer ${jwtToken}`,
        "Content-Type": "application/json; charset=UTF-8",
      },
  })
  .catch((error) => {
    console.log("Error: " + error);
  });

  // console.log(response.status);
  if(response.status === 200 ){
    const productBatch = await response.json();
    // console.log(productBatch);


    return productBatch;
  }

  else {
      return null;
  }
};

const getAllProductBatches = async(jwtToken) => {
  

  // console.log(requestObject);

  const response = await fetch(`${wupoServerUri.devnet}/api/secure/allProductBatch`, {
      method: "POST",
      // body: JSON.stringify(requestObject),
      credentials: 'include',
      headers: {
        "Authorization": `Bearer ${jwtToken}`,
      //   "Content-Type": "application/json; charset=UTF-8",
      },
  })
  .catch((error) => {
    console.log("Error: " + error);
  });

  // console.log(response.status);
  if(response.status === 200 ){
    const batches = await response.json();
    // console.log(batches);


    return batches;
  }

  else {
      return null;
  }
};

const getProductBatchPage = async(requestObject, jwtToken) => {
  
  // console.log(requestObject);

  const response = await fetch(`${wupoServerUri.devnet}/api/secure/allProductBatchPage`, {
      method: "POST",
      body: JSON.stringify(requestObject),
      credentials: 'include',
      headers: {
        "Authorization": `Bearer ${jwtToken}`,
        "Content-Type": "application/json; charset=UTF-8",
      },
  })
  .catch((error) => {
    console.log("Error: " + error);
  });

  // console.log(response.status);
  if(response.status === 200 ){
    const productBatch = await response.json();
    // console.log(productBatch);


    return productBatch;
  }

  else {
      return null;
  }
};

const updateCheckpoint = async(requestObject, jwtToken) => {
  
  // console.log(requestObject);

  const response = await fetch(`${wupoServerUri.devnet}/api/secure/warehouseCheckpoint`, {
      method: "POST",
      body: JSON.stringify(requestObject),
      credentials: 'include',
      headers: {
        "Authorization": `Bearer ${jwtToken}`,
        "Content-Type": "application/json; charset=UTF-8",
      },
  })
  .catch((error) => {
    console.log("Error: " + error);
  });

  // console.log(response.status);
  if(response.status === 200 ){
    const productBatch = await response.json();
    // console.log(productBatch);


    return productBatch;
  }

  else {
      return null;
  }
};

const updateCediCheckpoint = async(requestObject, jwtToken) => {
  
  console.log(requestObject);

  const response = await fetch(`${wupoServerUri.devnet}/api/secure/cediCheckpoint`, {
      method: "POST",
      body: JSON.stringify(requestObject),
      credentials: 'include',
      headers: {
        "Authorization": `Bearer ${jwtToken}`,
        "Content-Type": "application/json; charset=UTF-8",
      },
  })
  .catch((error) => {
    console.log("Error: " + error);
  });

  // console.log(response.status);
  if(response.status === 200 ){
    const productBatch = await response.json();
    console.log(productBatch);


    return productBatch;
  }

  else {
      return null;
  }
};

const getActiveProductBatches = async(jwtToken) => {
  

  // console.log(requestObject);

  const response = await fetch(`${wupoServerUri.devnet}/api/secure/activeProductBatch`, {
      method: "POST",
      // body: JSON.stringify(requestObject),
      credentials: 'include',
      headers: {
        "Authorization": `Bearer ${jwtToken}`,
      //   "Content-Type": "application/json; charset=UTF-8",
      },
  })
  .catch((error) => {
    console.log("Error: " + error);
  });

  // console.log(response.status);
  if(response.status === 200 ){
    const batches = await response.json();
    // console.log(batches);


    return batches;
  }

  else {
      return null;
  }
};

const createNewPosition = async(requestObject, jwtToken) => {
  
  // console.log(requestObject);

  const response = await fetch(`${wupoServerUri.devnet}/api/secure/registerPosition`, {
      method: "POST",
      body: JSON.stringify(requestObject),
      credentials: 'include',
      headers: {
        "Authorization": `Bearer ${jwtToken}`,
        "Content-Type": "application/json; charset=UTF-8",
      },
  })
  .catch((error) => {
    console.log("Error: " + error);
  });

  // console.log(response.status);
  if(response.status === 200 ){
    const productBatch = await response.json();
    // console.log(productBatch);


    return productBatch;
  }

  else {
      return null;
  }
};

const getInventory = async(jwtToken) => {
  

  // console.log(requestObject);

  const response = await fetch(`${wupoServerUri.devnet}/api/secure/warehouseInventory`, {
      method: "POST",
      // body: JSON.stringify(requestObject),
      credentials: 'include',
      headers: {
        "Authorization": `Bearer ${jwtToken}`,
      //   "Content-Type": "application/json; charset=UTF-8",
      },
  })
  .catch((error) => {
    console.log("Error: " + error);
  });

  // console.log(response.status);
  if(response.status === 200 ){
    const products = await response.json();
    // console.log(products);


    return products;
  }

  else {
      return null;
  }
};

const productBatchChange = async(requestObject, jwtToken) => {
  
  // console.log(requestObject);

  const response = await fetch(`${wupoServerUri.devnet}/api/secure/batchChange`, {
      method: "POST",
      body: JSON.stringify(requestObject),
      credentials: 'include',
      headers: {
        "Authorization": `Bearer ${jwtToken}`,
        "Content-Type": "application/json; charset=UTF-8",
      },
  })
  .catch((error) => {
    console.log("Error: " + error);
  });

  // console.log(response.status);
  if(response.status === 200 ){
    const productBatch = await response.json();
    // console.log(productBatch);


    return productBatch;
  }

  else {
      return null;
  }
};

const createDeliveryOrder = async(requestObject, jwtToken) => {
  
  // console.log(requestObject);

  const response = await fetch(`${wupoServerUri.devnet}/api/secure/warehouseOrder`, {
      method: "POST",
      body: JSON.stringify(requestObject),
      credentials: 'include',
      headers: {
        "Authorization": `Bearer ${jwtToken}`,
        "Content-Type": "application/json; charset=UTF-8",
      },
  })
  .catch((error) => {
    console.log("Error: " + error);
  });

  // console.log(response.status);
  if(response.status === 200 ){
    const delOrder = await response.json();
    // console.log(delOrder);


    return delOrder;
  }

  else {
      return null;
  }
};

const updateDeliveryOrder = async(requestObject, jwtToken) => {
  
  // console.log(requestObject);

  const response = await fetch(`${wupoServerUri.devnet}/api/secure/warehouseOrderinput`, {
      method: "POST",
      body: JSON.stringify(requestObject),
      credentials: 'include',
      headers: {
        "Authorization": `Bearer ${jwtToken}`,
        "Content-Type": "application/json; charset=UTF-8",
      },
  })
  .catch((error) => {
    console.log("Error: " + error);
  });

  // console.log(response.status);
  if(response.status === 200 ){
    const delOrder = await response.json();
    // console.log(delOrder);


    return delOrder;
  }

  else {
      return null;
  }
};

const getOrdersReady = async(jwtToken) => {
  

  // console.log(requestObject);

  const response = await fetch(`${wupoServerUri.devnet}/api/secure/warehouseReadyOrders`, {
      method: "POST",
      // body: JSON.stringify(requestObject),
      credentials: 'include',
      headers: {
        "Authorization": `Bearer ${jwtToken}`,
      //   "Content-Type": "application/json; charset=UTF-8",
      },
  })
  .catch((error) => {
    console.log("Error: " + error);
  });

  // console.log(response.status);
  if(response.status === 200 ){
    const products = await response.json();
    // console.log(products);


    return products;
  }

  else {
      return null;
  }
};

const getOrdersReadyPage = async(requestObject, jwtToken) => {
  
  // console.log(requestObject);

  const response = await fetch(`${wupoServerUri.devnet}/api/secure/warehouseReadyOrdersPage`, {
      method: "POST",
      body: JSON.stringify(requestObject),
      credentials: 'include',
      headers: {
        "Authorization": `Bearer ${jwtToken}`,
        "Content-Type": "application/json; charset=UTF-8",
      },
  })
  .catch((error) => {
    console.log("Error: " + error);
  });
  
  if(!response){
    return null;
  }
    
  if(response.status === 200 ){
    const orders = await response.json();
    // console.log(orders);


    return orders;
  }

  else {
      return null;
  }
};

const getProductByDescription = async(requestObject, jwtToken) => {
  
  // console.log(requestObject);

  const response = await fetch(`${wupoServerUri.devnet}/api/secure/warehouseOrderProducts`, {
      method: "POST",
      body: JSON.stringify(requestObject),
      credentials: 'include',
      headers: {
        "Authorization": `Bearer ${jwtToken}`,
        "Content-Type": "application/json; charset=UTF-8",
      },
  })
  .catch((error) => {
    console.log("Error: " + error);
  });

  // console.log(response.status);
  if(response.status === 200 ){
    const batches = await response.json();
    // console.log(batches);


    return batches;
  }

  else {
      return null;
  }
};

const getTransportProductByDescription = async(requestObject, jwtToken) => {
  
  // console.log(requestObject);

  const response = await fetch(`${wupoServerUri.devnet}/api/secure/warehouseTransporOrderProducts`, {
      method: "POST",
      body: JSON.stringify(requestObject),
      credentials: 'include',
      headers: {
        "Authorization": `Bearer ${jwtToken}`,
        "Content-Type": "application/json; charset=UTF-8",
      },
  })
  .catch((error) => {
    console.log("Error: " + error);
  });

  // console.log(response.status);
  if(response.status === 200 ){
    const batches = await response.json();
    // console.log(batches);


    return batches;
  }

  else {
      return null;
  }
};

const enableBatchesForPickup = async(requestObject, jwtToken) => {
  
  // console.log(requestObject);

  const response = await fetch(`${wupoServerUri.devnet}/api/secure/warehouseOrderPickup`, {
      method: "POST",
      body: JSON.stringify(requestObject),
      credentials: 'include',
      headers: {
        "Authorization": `Bearer ${jwtToken}`,
        "Content-Type": "application/json; charset=UTF-8",
      },
  })
  .catch((error) => {
    console.log("Error: " + error);
  });

  // console.log(response.status);
  if(response.status === 200 ){
    const order = await response.json();
    // console.log(order);


    return order;
  }

  else {
      return null;
  }
};

const particularOrderChange = async(requestObject, jwtToken) => {
  
  // console.log(requestObject);

  const response = await fetch(`${wupoServerUri.devnet}/api/secure/particularOrderChange`, {
      method: "POST",
      body: JSON.stringify(requestObject),
      credentials: 'include',
      headers: {
        "Authorization": `Bearer ${jwtToken}`,
        "Content-Type": "application/json; charset=UTF-8",
      },
  })
  .catch((error) => {
    console.log("Error: " + error);
  });

  console.log(response.status);
  if(response.status === 200 ){
    const batch = await response.json();
    // console.log(batch);


    return batch;
  }

  else {
      return null;
  }
};

const getAllTransportOrders = async(jwtToken) => {
  

  // console.log(requestObject);

  const response = await fetch(`${wupoServerUri.devnet}/api/secure/allTransportOrders`, {
      method: "POST",
      // body: JSON.stringify(requestObject),
      credentials: 'include',
      headers: {
        "Authorization": `Bearer ${jwtToken}`,
      //   "Content-Type": "application/json; charset=UTF-8",
      },
  })
  .catch((error) => {
    console.log("Error: " + error);
  });

  // console.log(response.status);
  if(response.status === 200 ){
    const orders = await response.json();
    // console.log(orders);


    return orders;
  }

  else {
      return null;
  }
};

const getAllRawTransportOrders = async(jwtToken) => {
  

  // console.log(requestObject);

  const response = await fetch(`${wupoServerUri.devnet}/api/secure/rawTransportOrders`, {
      method: "POST",
      // body: JSON.stringify(requestObject),
      credentials: 'include',
      headers: {
        "Authorization": `Bearer ${jwtToken}`,
      //   "Content-Type": "application/json; charset=UTF-8",
      },
  })
  .catch((error) => {
    console.log("Error: " + error);
  });

  // console.log(response.status);
  if(response.status === 200 ){
    const orders = await response.json();
    // console.log(orders);


    return orders;
  }

  else {
      return null;
  }
};

const getTransportOrderResponse = async(requestObject, jwtToken) => {
  
  // console.log(requestObject);

  const response = await fetch(`${wupoServerUri.devnet}/api/secure/transportOrderResponse`, {
      method: "POST",
      body: JSON.stringify(requestObject),
      credentials: 'include',
      headers: {
        "Authorization": `Bearer ${jwtToken}`,
        "Content-Type": "application/json; charset=UTF-8",
      },
  })
  .catch((error) => {
    console.log("Error: " + error);
  });

  // console.log(response.status);
  if(response.status === 200 ){
    const order = await response.json();
    // console.log(order);

    return order;
  }

  else {
      return null;
  }
};

const getTransportOrder = async(requestObject, jwtToken) => {
  
  // console.log(requestObject);

  const response = await fetch(`${wupoServerUri.devnet}/api/secure/transportOrder`, {
      method: "POST",
      body: JSON.stringify(requestObject),
      credentials: 'include',
      headers: {
        "Authorization": `Bearer ${jwtToken}`,
        "Content-Type": "application/json; charset=UTF-8",
      },
  })
  .catch((error) => {
    console.log("Error: " + error);
  });

  // console.log(response.status);
  if(response.status === 200 ){
    const order = await response.json();
    // console.log(order);

    return order;
  }

  else {
      return null;
  }
};

const closeTransportOrder = async(requestObject, jwtToken) => {
  
  // console.log(requestObject);

  const response = await fetch(`${wupoServerUri.devnet}/api/secure/closeTransportOrder`, {
      method: "POST",
      body: JSON.stringify(requestObject),
      credentials: 'include',
      headers: {
        "Authorization": `Bearer ${jwtToken}`,
        "Content-Type": "application/json; charset=UTF-8",
      },
  })
  .catch((error) => {
    console.log("Error: " + error);
  });

  // console.log(response.status);
  if(response.status === 200 ){
    const order = await response.json();
    // console.log(order);

    return order;
  }

  else {
      return null;
  }
};

const newMasterProduct = async(requestObject, jwtToken) => {
  
  // console.log(requestObject);

  const response = await fetch(`${wupoServerUri.devnet}/api/secure/newWarehouseProduct`, {
      method: "POST",
      body: JSON.stringify(requestObject),
      credentials: 'include',
      headers: {
        "Authorization": `Bearer ${jwtToken}`,
        "Content-Type": "application/json; charset=UTF-8",
      },
  })
  .catch((error) => {
    console.log("Error: " + error);
  });

  // console.log(response.status);
  if(response.status === 200 ){
    const product = await response.json();
    // console.log(product);

    return product;
  }

  else {
      return null;
  }
};

const changeTransportOrder = async(requestObject, jwtToken) => {
  
  // console.log(requestObject);

  const response = await fetch(`${wupoServerUri.devnet}/api/secure/changeTransportOrder`, {
      method: "POST",
      body: JSON.stringify(requestObject),
      credentials: 'include',
      headers: {
        "Authorization": `Bearer ${jwtToken}`,
        "Content-Type": "application/json; charset=UTF-8",
      },
  })
  .catch((error) => {
    console.log("Error: " + error);
  });

  // console.log(response.status);
  if(response.status === 200 ){
    const order = await response.json();
    // console.log(order);

    return order;
  }

  else {
      return null;
  }
};

const disableForPickup = async(requestObject, jwtToken) => {
  
  // console.log(requestObject);

  const response = await fetch(`${wupoServerUri.devnet}/api/secure/disableBatchForPickup`, {
      method: "POST",
      body: JSON.stringify(requestObject),
      credentials: 'include',
      headers: {
        "Authorization": `Bearer ${jwtToken}`,
        "Content-Type": "application/json; charset=UTF-8",
      },
  })
  .catch((error) => {
    console.log("Error: " + error);
  });

  // console.log(response.status);
  if(response.status === 200 ){
    const batch = await response.json();
    // console.log(batch);

    return batch;
  }

  else {
      return null;
  }
};

const manualEnableForPickup = async(requestObject, jwtToken) => {
  
  // console.log(requestObject);

  const response = await fetch(`${wupoServerUri.devnet}/api/secure/manualWarehouseOrderPickup`, {
      method: "POST",
      body: JSON.stringify(requestObject),
      credentials: 'include',
      headers: {
        "Authorization": `Bearer ${jwtToken}`,
        "Content-Type": "application/json; charset=UTF-8",
      },
  })
  .catch((error) => {
    console.log("Error: " + error);
  });

  // console.log(response.status);
  if(response.status === 200 ){
    const body = await response.json();
    // console.log(body);

    return body;
  }

  else {
      return null;
  }
};

const changeIndividualsInOrder = async(requestObject, jwtToken) => {
  
  // console.log(requestObject);

  const response = await fetch(`${wupoServerUri.devnet}/api/secure/manualOrderAmountChange`, {
      method: "POST",
      body: JSON.stringify(requestObject),
      credentials: 'include',
      headers: {
        "Authorization": `Bearer ${jwtToken}`,
        "Content-Type": "application/json; charset=UTF-8",
      },
  })
  .catch((error) => {
    console.log("Error: " + error);
  });

  // console.log(response.status);
  if(response.status === 200 ){
    const order = await response.json();
    // console.log(order);

    return order;
  }

  else {
      return null;
  }
};

const manualAssignmentToOrder = async(requestObject, jwtToken) => {
  
  console.log(requestObject);

  const response = await fetch(`${wupoServerUri.devnet}/api/secure/manualAssignmentToOrder`, {
      method: "POST",
      body: JSON.stringify(requestObject),
      credentials: 'include',
      headers: {
        "Authorization": `Bearer ${jwtToken}`,
        "Content-Type": "application/json; charset=UTF-8",
      },
  })
  .catch((error) => {
    console.log("Error: " + error);
  });

  // console.log(response.status);
  if(response.status === 200 ){
    const order = await response.json();
    // console.log(order);

    return order;
  }

  else {
      return null;
  }
};

const activateLabelBatch = async(requestObject, jwtToken) => {
  
  // console.log(requestObject);

  const response = await fetch(`${wupoServerUri.devnet}/api/secure/activateLabelBatch`, {
      method: "POST",
      body: JSON.stringify(requestObject),
      credentials: 'include',
      headers: {
        "Authorization": `Bearer ${jwtToken}`,
        "Content-Type": "application/json; charset=UTF-8",
      },
  })
  .catch((error) => {
    console.log("Error: " + error);
  });

  // console.log(response.status);
  if(response.status === 200 ){
    const activateResponse = await response.json();
    // console.log(activateResponse);

    return activateResponse;
  }

  else {
      return null;
  }
};

const getOrderByCode = async(requestObject, jwtToken) => {
  
  // console.log(requestObject);

  const response = await fetch(`${wupoServerUri.devnet}/api/secure/orderByCode`, {
      method: "POST",
      body: JSON.stringify(requestObject),
      credentials: 'include',
      headers: {
        "Authorization": `Bearer ${jwtToken}`,
        "Content-Type": "application/json; charset=UTF-8",
      },
  })
  .catch((error) => {
    console.log("Error: " + error);
  });

  // console.log(response.status);
  if(response.status === 200 ){
    const body = await response.json();
    // console.log(body);

    return body;
  }

  else {
      return null;
  }
};

const qryproductBatch = (jwtToken) => {

  return(
    fetch(`${wupoServerUri.devnet}/api/secure/productBatchCsv`, {
        
        method: 'POST',
        // body: JSON.stringify(creditObject),
        headers: {
          'Authorization': `Bearer ${jwtToken}`, 
          'Content-Type': 'application/json'                     
        }
        
      }).then((response) => { 
        // console.log(response.status) // DEBUG PRINTING

        if(response.status === 200){
          // console.log(response.status);
          return response.blob();
        }
        else{
            return null;
        }
    
      }).then((blob) => {
          var url = window.URL.createObjectURL(blob);
          var a = document.createElement('a');
          a.href = url;
          a.download = "reporte_estibas.csv";
          document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
          a.click();    
          a.remove();  //afterwards we remove the element again      
          
          return "ok";
          
    }).catch(function(error){
        console.log("Error msg: "+ error);
    })
  );

};

const qryproductBatchCedi = (jwtToken) => {

  return(
    fetch(`${wupoServerUri.devnet}/api/secure/productBatchCediCsv`, {
        
        method: 'POST',
        // body: JSON.stringify(creditObject),
        headers: {
          'Authorization': `Bearer ${jwtToken}`, 
          'Content-Type': 'application/json'                     
        }
        
      }).then((response) => { 
        // console.log(response.status) // DEBUG PRINTING

        if(response.status === 200){
          // console.log(response.status);
          return response.blob();
        }
        else{
            return null;
        }
    
      }).then((blob) => {
          var url = window.URL.createObjectURL(blob);
          var a = document.createElement('a');
          a.href = url;
          a.download = "reporte_estibas.csv";
          document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
          a.click();    
          a.remove();  //afterwards we remove the element again      
          
          return "ok";
          
    }).catch(function(error){
        console.log("Error msg: "+ error);
    })
  );

};

const qryTotalInventory = (jwtToken) => {

  return(
    fetch(`${wupoServerUri.devnet}/api/secure/inventoryCsv`, {
        
        method: 'POST',
        // body: JSON.stringify(creditObject),
        headers: {
          'Authorization': `Bearer ${jwtToken}`, 
          'Content-Type': 'application/json'                     
        }
        
      }).then((response) => { 
        // console.log(response.status) // DEBUG PRINTING

        if(response.status === 200){
          // console.log(response.status);
          return response.blob();
        }
        else{
            return null;
        }
    
      }).then((blob) => {
          var url = window.URL.createObjectURL(blob);
          var a = document.createElement('a');
          a.href = url;
          a.download = "inventoario_total.csv";
          document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
          a.click();    
          a.remove();  //afterwards we remove the element again      
          
          return "ok";
          
    }).catch(function(error){
        console.log("Error msg: "+ error);
    })
  );

};

const qryDailyInventory = (jwtToken) => {

  return(
    fetch(`${wupoServerUri.devnet}/api/secure/dailyInventoryCsv`, {
        
        method: 'POST',
        // body: JSON.stringify(creditObject),
        headers: {
          'Authorization': `Bearer ${jwtToken}`, 
          'Content-Type': 'application/json'                     
        }
        
      }).then((response) => { 
        // console.log(response.status) // DEBUG PRINTING

        if(response.status === 200){
          // console.log(response.status);
          return response.blob();
        }
        else{
            return null;
        }
    
      }).then((blob) => {
          var url = window.URL.createObjectURL(blob);
          var a = document.createElement('a');
          a.href = url;
          a.download = "inventoario_diario.csv";
          document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
          a.click();    
          a.remove();  //afterwards we remove the element again      
          
          return "ok";
          
    }).catch(function(error){
        console.log("Error msg: "+ error);
    })
  );

};

const getActiveTransportOrdersIdentifiers = async(jwtToken) => {
  

  // console.log(requestObject);

  const response = await fetch(`${wupoServerUri.devnet}/api/secure/activeTransportOrderIdentifiers`, {
      method: "POST",
      // body: JSON.stringify(requestObject),
      credentials: 'include',
      headers: {
        "Authorization": `Bearer ${jwtToken}`,
      //   "Content-Type": "application/json; charset=UTF-8",
      },
  })
  .catch((error) => {
    console.log("Error: " + error);
  });

  // console.log(response.status);
  if(response.status === 200 ){
    const orders = await response.json();
    // console.log(orders);


    return orders;
  }

  else {
      return null;
  }
};

const manualBatchToTransportOrder = async(requestObject, jwtToken) => {
  
  // console.log(requestObject);

  const response = await fetch(`${wupoServerUri.devnet}/api/secure/manualBatchToTransportOrder`, {
      method: "POST",
      body: JSON.stringify(requestObject),
      credentials: 'include',
      headers: {
        "Authorization": `Bearer ${jwtToken}`,
        "Content-Type": "application/json; charset=UTF-8",
      },
  })
  .catch((error) => {
    console.log("Error: " + error);
  });

  // console.log(response.status);
  if(response.status === 200 ){
    const order = await response.json();
    // console.log(order);

    return order;
  }

  else {
      return null;
  }
};

const getAllCediOrder = async(jwtToken) => {
  

  // console.log(requestObject);

  const response = await fetch(`${wupoServerUri.devnet}/api/secure/allCediOrders`, {
      method: "POST",
      // body: JSON.stringify(requestObject),
      credentials: 'include',
      headers: {
        "Authorization": `Bearer ${jwtToken}`,
      //   "Content-Type": "application/json; charset=UTF-8",
      },
  })
  .catch((error) => {
    console.log("Error: " + error);
  });

  // console.log(response.status);
  if(response.status === 200 ){
    const object = await response.json();
    // console.log(orders);


    return object;
  }

  else {
      return null;
  }
};

const getOpenCediOrders = async(jwtToken) => {
  

  const response = await fetch(`${wupoServerUri.devnet}/api/secure/openCediOrders`, {
      method: "POST",
      // body: JSON.stringify(requestObject),
      credentials: 'include',
      headers: {
        "Authorization": `Bearer ${jwtToken}`,
      //   "Content-Type": "application/json; charset=UTF-8",
      },
  })
  .catch((error) => {
    console.log("Error: " + error);
  });

  // console.log(response.status);
  if(response.status === 200 ){
    const object = await response.json();
    console.log(object);


    return object;
  }

  else {
      return null;
  }
};

const getClosedCediOrders = async(jwtToken) => {
  

  // console.log(requestObject);

  const response = await fetch(`${wupoServerUri.devnet}/api/secure/closedCediOrders`, {
      method: "POST",
      // body: JSON.stringify(requestObject),
      credentials: 'include',
      headers: {
        "Authorization": `Bearer ${jwtToken}`,
      //   "Content-Type": "application/json; charset=UTF-8",
      },
  })
  .catch((error) => {
    console.log("Error: " + error);
  });

  // console.log(response.status);
  if(response.status === 200 ){
    const object = await response.json();
    // console.log(orders);


    return object;
  }

  else {
      return null;
  }
};

const closeCediOrder = async(requestObject, jwtToken) => {
  
  console.log(requestObject);

  const response = await fetch(`${wupoServerUri.devnet}/api/secure/closeCediOrder`, {
      method: "POST",
      body: JSON.stringify(requestObject),
      credentials: 'include',
      headers: {
        "Authorization": `Bearer ${jwtToken}`,
        "Content-Type": "application/json; charset=UTF-8",
      },
  })
  .catch((error) => {
    console.log("Error: " + error);
  });

  // console.log(response.status);
  if(response.status === 200 ){
    const order = await response.json();
    // console.log(order);

    return order;
  }

  else {
      return null;
  }
};

const getCediOrder = async(requestObject, jwtToken) => {
  
  // console.log(requestObject);

  const response = await fetch(`${wupoServerUri.devnet}/api/secure/getCediOrder`, {
      method: "POST",
      body: JSON.stringify(requestObject),
      credentials: 'include',
      headers: {
        "Authorization": `Bearer ${jwtToken}`,
        "Content-Type": "application/json; charset=UTF-8",
      },
  })
  .catch((error) => {
    console.log("Error: " + error);
  });

  // console.log(response.status);
  if(response.status === 200 ){
    const order = await response.json();
    // console.log(order);

    return order;
  }

  else {
      return null;
  }
};

const getCediOrdersPage = async(requestObject, jwtToken) => {
  
  console.log(requestObject);

  const response = await fetch(`${wupoServerUri.devnet}/api/secure/allCediOrdersPage`, {
      method: "POST",
      body: JSON.stringify(requestObject),
      credentials: 'include',
      headers: {
        "Authorization": `Bearer ${jwtToken}`,
        "Content-Type": "application/json; charset=UTF-8",
      },
  })
  .catch((error) => {
    console.log("Error: " + error);
  });

  // console.log(response.status);
  if(response.status === 200 ){
    const productBatch = await response.json();
    console.log(productBatch);


    return productBatch;
  }

  else {
      return null;
  }
};

const createCediOrder = async(requestObject, jwtToken) => {
  
  console.log(requestObject);

  const response = await fetch(`${wupoServerUri.devnet}/api/secure/createCediOrders`, {
      method: "POST",
      body: JSON.stringify(requestObject),
      credentials: 'include',
      headers: {
        "Authorization": `Bearer ${jwtToken}`,
        "Content-Type": "application/json; charset=UTF-8",
      },
  })
  .catch((error) => {
    console.log("Error: " + error);
  });

  console.log(response.status);
  if(response.status === 200 ){
    const productBatch = await response.json();
    console.log(productBatch);


    return productBatch;
  }

  else {
      return null;
  }
};

const addBatchToCediOrder = async(requestObject, jwtToken) => {
  
  console.log(requestObject);

  const response = await fetch(`${wupoServerUri.devnet}/api/secure/addBatchToCediOrder`, {
      method: "POST",
      body: JSON.stringify(requestObject),
      credentials: 'include',
      headers: {
        "Authorization": `Bearer ${jwtToken}`,
        "Content-Type": "application/json; charset=UTF-8",
      },
  })
  .catch((error) => {
    console.log("Error: " + error);
  });

  if(response.status === 200 ){
    const responseBody = await response.json();
    console.log(responseBody);

    return responseBody;
  }

  else {
      return null;
  }
};

const removeBatchFromCediOrder = async(requestObject, jwtToken) => {
  
  console.log(requestObject);

  const response = await fetch(`${wupoServerUri.devnet}/api/secure/removeBatchFromCediOrder`, {
      method: "POST",
      body: JSON.stringify(requestObject),
      credentials: 'include',
      headers: {
        "Authorization": `Bearer ${jwtToken}`,
        "Content-Type": "application/json; charset=UTF-8",
      },
  })
  .catch((error) => {
    console.log("Error: " + error);
  });

  if(response.status === 200 ){
    const responseBody = await response.json();
    console.log(responseBody);

    return responseBody;
  }

  else {
      return null;
  }
};

const generateOrderCediExcelReport = (requestObject, jwtToken) => {

  console.log(requestObject);

  return(
    fetch(`${wupoServerUri.devnet}/api/secure/generateCediOrderReport`, {
        
        method: 'POST',
        body: JSON.stringify(requestObject),
        headers: {
          'Authorization': `Bearer ${jwtToken}`, 
          'Content-Type': 'application/json'                     
        }
        
      }).then((response) => { 
        // console.log(response.status) // DEBUG PRINTING

        if(response.status === 200){
          // console.log(response.status);
          return response.blob();
        }
        else{
            return null;
        }
    
      }).then((blob) => {
          var url = window.URL.createObjectURL(blob);
          var a = document.createElement('a');
          a.href = url;
          a.download = "reporte.xlsx";
          document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
          a.click();    
          a.remove();  //afterwards we remove the element again      
          
          return "ok";
          
    }).catch(function(error){
        console.log("Error msg: "+ error);
    })
  );

};

export { createWarehouseLabel, getWarehouseCodes, getProductBatch, updateCheckpoint, getAllProductBatches, 
  getActiveProductBatches, createNewPosition, discardProductBatch, getInventory, productBatchChange, createDeliveryOrder,
  updateDeliveryOrder, getOrdersReady, getProductByDescription, enableBatchesForPickup, particularOrderChange,
  getAllTransportOrders, getTransportOrder, closeTransportOrder, newMasterProduct, changeTransportOrder, disableForPickup,
  changeIndividualsInOrder, qryproductBatch, qryTotalInventory, qryDailyInventory, activateLabelBatch, getOrdersReadyPage,
  getProductBatchPage, getOrderByCode, manualEnableForPickup, getTransportProductByDescription,
  getActiveTransportOrdersIdentifiers, manualBatchToTransportOrder, manualAssignmentToOrder, getAllRawTransportOrders, 
  getTransportOrderResponse, getAllCediOrder, getOpenCediOrders, getClosedCediOrders, closeCediOrder, getCediOrder, 
  getCediOrdersPage, createCediOrder, updateCediCheckpoint, qryproductBatchCedi, addBatchToCediOrder,
  removeBatchFromCediOrder, generateOrderCediExcelReport};