import React, {useEffect} from 'react'

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import makeStyles from '@mui/styles/makeStyles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import CardActionArea from '@mui/material/CardActionArea';
import Colors from "../../components/ui/Colors";
import { css } from "@emotion/react";
import { PuffLoader } from "react-spinners";
import {changeSecondFactor} from "../../utilityFunctions/SecurityUtil"
import swal from "sweetalert";
import { useHistory } from "react-router-dom";
import { FaSearch } from "react-icons/fa";
import { IoSettings } from "react-icons/io5";
import { AiFillLock } from "react-icons/ai";
import { BsFillPersonFill } from "react-icons/bs";
import { RiAdminLine } from "react-icons/ri";
import { FaChartLine } from "react-icons/fa";
import {F2Explanation} from "../../Texts/Explanations.js"
import TextField from '@mui/material/TextField';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { TrustSetFlags } from 'xrpl';
import { ClientRol } from '../admin';
import Grid from '@mui/material/Grid';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Box from '@mui/material/Box';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import {AdminRol} from '../admin';
import {AdminSettings} from '../admin';
import {dealsAnalytics, sendEmails} from "../../utilityFunctions/DealsUtil";

const vanking = false;
const nutrion = true;

const secondFactorEnabled = false;

const useStyles = makeStyles((theme) => ({
  
    dialogStyle:{
      whiteSpace: "pre-line",
      
    },
  
    dialog:{
      backgroundColor: "#DCDCDC",
    },
    
    linkStyle:{
      cursor: "pointer",
      color: "inherit",
      textAlign: "center"
    },
  
    actionArea: {
      borderRadius: 16,
      transition: '0.2s',
      '&:hover': {
        transform: 'scale(1.1)',
        backgroundColor: Colors.primary,
      },
    },
  
    cardImage:{
      width: "20%",
      justifySelf: "center"
    }
  
  }));

  const cssLoader = css`
  display: block;
  margin-left: auto;
  margin-right: auto;
`;
  
let openAdmin=false;
let openQrys=false;
let openSettings=false;
let openDeals=false;
let openDeliveries=false;
let openMeketPlace=false;
let openWarehouse=false;

let isProd=false;
let isSupervisor=false;
let isTransporter=false;
  
export default function HanldeProfile(props) {
    const {
        loginUser, loadProductsAndSetting, updateDealsDefinitions, updateDealsList, warehouseProducts, getWarehouseProducts
    } = props

    let history = useHistory();

    const classes = useStyles();

    const [loading, setLoading] = React.useState(false);

    useEffect(() => {
        
        // handleChooseProduct();
        // loadDealsInfo();
        // loadAllDeals();
        handleAdmin();
        updateWarehouseProducts();
    }, []);

    const updateWarehouseProducts = async () => {
        if(warehouseProducts){
            return;
        }

        await getWarehouseProducts(loginUser.jwtToken);

    }

    const loadDealsInfo = async () => {
        console.log("getting definitions info from back")
        setLoading(true);
        await updateDealsDefinitions(loginUser.jwtToken);
        setLoading(false);
    };

    const loadAllDeals = async () => {
        console.log("getting all deals")
        setLoading(true);
        await updateDealsList(loginUser.jwtToken);
        setLoading(false);
    };

    const [profileOptions, setProfileOptions] = React.useState("");

    const handleDealRequest = (dealAction) => {
        if(dealAction==="create"){
            history.push({pathname: "/commercial/newdealsdef"});
            return;
        }
        if(dealAction==="review"){
            history.push({pathname: "/commercial/nreviewdealsdef"});
            return;
        }
        if(dealAction==="closed"){
            history.push({pathname: "/commercial/closedDeals"});
            return;
        }
        if(dealAction==="clients"){
            history.push({pathname: "/commercial/clientSearch"});
            return;
        }
        
    };

    const analyticsExecution = async () => {
        setLoading(true);
        const result = await dealsAnalytics(loginUser.jwtToken);
        console.log(result);
        setLoading(false);
    };

    const enableDeals = async () => {
        setLoading(true);
        const result = await sendEmails(loginUser.jwtToken);
        console.log(result);
        setLoading(false);
    };

    const handleDeliveries = () => {
        history.push({pathname: "/lastmile/board"});
    };

    const createDeliveries = () => {
        history.push({pathname: "/lastmile/batch"});
    };

    const createDelivery = () => {
        history.push({pathname: "/lastmile/newOrder"});
    };
    const createnewStatus= () => {
        history.push({pathname: "/lastmile/newStatus"});
    };
    const createPQR= () => {
        history.push({pathname: "/lastmile/newPqr"});
    };
    const createProblem= () => {
        history.push({pathname: "/lastmile/newDelay"});
    };
    const searchOrder= () => {
        history.push({pathname: "/lastmile/orderSearch"});
    };

    const newProduct = () => {
        history.push({pathname: "/portfolio/create-products"});
    };

    const changeStatus = () => {
        history.push({pathname: "/admin/change-status"});
    };

    const cattleSettlement = () => {
        history.push({pathname: "/portfolio/cattle-settlement"});
    };
    const clientPayments = () => {
        history.push({pathname: "/settlement/manual-payments"});
    };
    const docsValidation = () => {
        history.push({pathname: "/info/validation"});
    };
    const cattlesDetail = () => {
        history.push({pathname: "/info/purchases"});
    };
    const findClients = () => {
        history.push({pathname: "/info/client"});
    }; 
    const goToQrys = () => {
        history.push({pathname: "/admin/market-qrys"});
    };
    const createQrLabel = () => {
        history.push({pathname: "/warehouse/batch-label"});
    };
    const gotToCheckpoint = () => {
        history.push({pathname: "/warehouse/checkpoint"});
    };
    const gotToBatches = () => {
        history.push({pathname: "/warehouse/batches"});
    };
    const gotToWarehouse = () => {
        history.push({pathname: "/warehouse/structure"});
    };
    const gotToInventory = () => {
        history.push({pathname: "/warehouse/inventory"});
    };
    const gotToDurations = () => {
        history.push({pathname: "/warehouse/durations"});
    };
    const gotTobatchChanges = () => {
        history.push({pathname: "/warehouse/batch-changes"});
    };
    const gotTobatchChangesReport = () => {
        history.push({pathname: "/warehouse/batch-changes-report"});
    };
    const goToCreateOrders = () => {
        history.push({pathname: "/warehouse/create-orders"});
    };
    // const goToUpdateOrder = () => {
    //     history.push({pathname: "/warehouse/update-orders"});
    // };
    const goToActiveOrders = () => {
        history.push({pathname: "/warehouse/active-orders"});
    };
    const goToPendingOrders = () => {
        history.push({pathname: "/warehouse/pending-order"});
    };
    const goToTransportOrders = () => {
        history.push({pathname: "/warehouse/transport-orders-list"});
    };
    const goToCediOrders = () => {
        history.push({pathname: "/warehouse/cedi-orders-list"});
    };
    const goToNewCediOrder = () => {
        history.push({pathname: "/warehouse/new-cedi-order"});
    };
    const goToNewWhProduct = () => {
        history.push({pathname: "/warehouse/new-product"});
    };
    const goToCsvWarehouse = () => {
        history.push({pathname: "/warehouse/csv-reports"});
    };
    const goToNewUser = () => {
        history.push({pathname: "/auth/new-user"});
    };
    
    
    
    const profileOptionsDrawer = (isAdminProfile) => {
        setProfileOptions(
            <List sx={{width: "100%"}}>
                {isAdminProfile ?
                    <>
                    <ListItem disablePadding>
                        <ListItemButton onClick={handleExpand} sx={{justifyContent: "end"}}>   
                            <ListItemText primary={"Administrador"}/>
                            {openAdmin ? <ExpandLess /> : <ExpandMore />}   
                        </ListItemButton>
                    </ListItem>
                    <Collapse in={openAdmin} timeout="auto">
                        <List>
                            <>
                            <ListItem disablePadding>
                                <ListItemButton onClick={handleExpandWarehouse} sx={{justifyContent: "center"}}>
                                    <Grid container direction="column">
                                        <Grid item xs={12} sm={12} md={12} lg={12}>
                                                <ListItem disablePadding>
                                                    <ListItemButton onClick={() => goToNewUser("one")} sx={{justifyContent: "center"}}>
                                                        <ListItemText primary={"Creación Usuario"} primaryTypographyProps={{variant: "body2", color: "GrayText"}}/>
                                                    </ListItemButton>
                                                </ListItem>
                                                <ListItem disablePadding>
                                                    <ListItemButton onClick={() => goToNewWhProduct("one")} sx={{justifyContent: "center"}}>
                                                        <ListItemText primary={"Creación Producto"} primaryTypographyProps={{variant: "body2", color: "GrayText"}}/>
                                                    </ListItemButton>
                                                </ListItem>
                                                <ListItem disablePadding>
                                                    <ListItemButton onClick={() => createQrLabel("one")} sx={{justifyContent: "center"}}>
                                                        <ListItemText primary={"Creación QR"} primaryTypographyProps={{variant: "body2", color: "GrayText"}}/>
                                                    </ListItemButton>
                                                </ListItem>
                                                <ListItem disablePadding>
                                                    <ListItemButton onClick={() => gotToCheckpoint("one")} sx={{justifyContent: "center"}}>
                                                        <ListItemText primary={"Checkpoint"} primaryTypographyProps={{variant: "body2", color: "GrayText"}}/>
                                                    </ListItemButton>
                                                </ListItem>
                                                <ListItem disablePadding>
                                                    <ListItemButton onClick={() => gotToBatches("one")} sx={{justifyContent: "center"}}>
                                                        <ListItemText primary={"Estibas"} primaryTypographyProps={{variant: "body2", color: "GrayText"}}/>
                                                    </ListItemButton>
                                                </ListItem>
                                                <ListItem disablePadding>
                                                    <ListItemButton onClick={() => gotToWarehouse("one")} sx={{justifyContent: "center"}}>
                                                        <ListItemText primary={"Bodega"} primaryTypographyProps={{variant: "body2", color: "GrayText"}}/>
                                                    </ListItemButton>
                                                </ListItem>
                                                <ListItem disablePadding>
                                                    <ListItemButton onClick={() => gotToInventory()} sx={{justifyContent: "center"}}>
                                                        <ListItemText primary={"Inventario"} primaryTypographyProps={{variant: "body2", color: "GrayText"}}/>
                                                    </ListItemButton>
                                                </ListItem>
                                                <ListItem disablePadding>
                                                    <ListItemButton onClick={() => gotToDurations()} sx={{justifyContent: "center"}}>
                                                        <ListItemText primary={"Tiempos"} primaryTypographyProps={{variant: "body2", color: "GrayText"}}/>
                                                    </ListItemButton>
                                                </ListItem>
                                                <ListItem disablePadding>
                                                    <ListItemButton onClick={() => gotTobatchChanges()} sx={{justifyContent: "center"}}>
                                                        <ListItemText primary={"Cambio Estiba"} primaryTypographyProps={{variant: "body2", color: "GrayText"}}/>
                                                    </ListItemButton>
                                                </ListItem>
                                                <ListItem disablePadding>
                                                    <ListItemButton onClick={() => gotTobatchChangesReport()} sx={{justifyContent: "center"}}>
                                                        <ListItemText primary={"Reporte Cambios"} primaryTypographyProps={{variant: "body2", color: "GrayText"}}/>
                                                    </ListItemButton>
                                                </ListItem>
                                                <ListItem disablePadding>
                                                    <ListItemButton onClick={() => goToCreateOrders()} sx={{justifyContent: "center"}}>
                                                        <ListItemText primary={"Cargar Órdenes"} primaryTypographyProps={{variant: "body2", color: "GrayText"}}/>
                                                    </ListItemButton>
                                                </ListItem>
                                                <ListItem disablePadding>
                                                    <ListItemButton onClick={() => goToPendingOrders()} sx={{justifyContent: "center"}}>
                                                        <ListItemText primary={"Órdenes Pendientes"} primaryTypographyProps={{variant: "body2", color: "GrayText"}}/>
                                                    </ListItemButton>
                                                </ListItem>
                                                <ListItem disablePadding>
                                                    <ListItemButton onClick={() => goToTransportOrders()} sx={{justifyContent: "center"}}>
                                                        <ListItemText primary={"Órdenes Despacho"} primaryTypographyProps={{variant: "body2", color: "GrayText"}}/>
                                                    </ListItemButton>
                                                </ListItem>
                                                <ListItem disablePadding>
                                                    <ListItemButton onClick={goToCsvWarehouse} sx={{justifyContent: "center"}}>
                                                        <ListItemText primary={"Descarga Reportes"} primaryTypographyProps={{variant: "body2", color: "GrayText"}}/>
                                                    </ListItemButton>
                                                </ListItem>
                                                <ListItem disablePadding>
                                                    <ListItemButton onClick={() => goToCediOrders()} sx={{justifyContent: "center"}}>
                                                        <ListItemText primary={"Órdenes Cedi"} primaryTypographyProps={{variant: "body2", color: "GrayText"}}/>
                                                    </ListItemButton>
                                                </ListItem>
                                                {/* <ListItem disablePadding>
                                                    <ListItemButton onClick={() => goToNewCediOrder()} sx={{justifyContent: "center"}}>
                                                        <ListItemText primary={"Nueva orden Cedi"} primaryTypographyProps={{variant: "body2", color: "GrayText"}}/>
                                                    </ListItemButton>
                                                </ListItem> */}
                                                
                                        </Grid>
                                    </Grid>
                                </ListItemButton>
                            </ListItem>
                            </>
                        </List>
                    </Collapse>
                    </>
                    :<></>
                }
                {isProd &&
                    <>
                    <ListItem disablePadding>
                        <ListItemButton onClick={handleExpand} sx={{justifyContent: "end"}}>   
                            <ListItemText primary={"Producción"}/>
                            {openAdmin ? <ExpandLess /> : <ExpandMore />}   
                        </ListItemButton>
                    </ListItem>
                    <Collapse in={openAdmin} timeout="auto">
                        <List>
                            <ListItem disablePadding>
                                <ListItemButton onClick={handleExpandWarehouse} sx={{justifyContent: "center"}}>
                                    <Grid container direction="column">
                                        <Grid item xs={12} sm={12} md={12} lg={12}>
                                                <ListItem disablePadding>
                                                    <ListItemButton onClick={() => createQrLabel("one")} sx={{justifyContent: "center"}}>
                                                        <ListItemText primary={"Creación QR"} primaryTypographyProps={{variant: "body2", color: "GrayText"}}/>
                                                    </ListItemButton>
                                                </ListItem>
                                        </Grid>
                                    </Grid>
                                </ListItemButton>
                            </ListItem>
                        </List>
                    </Collapse>
                    </>

                }
                {isTransporter &&
                    <>
                    <ListItem disablePadding>
                        <ListItemButton onClick={handleExpand} sx={{justifyContent: "end"}}>   
                            <ListItemText primary={"Montacargas"}/>
                            {openAdmin ? <ExpandLess /> : <ExpandMore />}   
                        </ListItemButton>
                    </ListItem>
                    <Collapse in={openAdmin} timeout="auto">
                        <List>
                            <ListItem disablePadding>
                                <ListItemButton onClick={handleExpandWarehouse} sx={{justifyContent: "center"}}>
                                    <Grid container direction="column">
                                        <Grid item xs={12} sm={12} md={12} lg={12}>
                                                <ListItem disablePadding>
                                                    <ListItemButton onClick={() => createQrLabel("one")} sx={{justifyContent: "center"}}>
                                                        <ListItemText primary={"Creación QR"} primaryTypographyProps={{variant: "body2", color: "GrayText"}}/>
                                                    </ListItemButton>
                                                </ListItem>
                                                <ListItem disablePadding>
                                                    <ListItemButton onClick={() => gotToCheckpoint("one")} sx={{justifyContent: "center"}}>
                                                        <ListItemText primary={"Checkpoint"} primaryTypographyProps={{variant: "body2", color: "GrayText"}}/>
                                                    </ListItemButton>
                                                </ListItem>
                                                <ListItem disablePadding>
                                                    <ListItemButton onClick={() => gotToWarehouse("one")} sx={{justifyContent: "center"}}>
                                                        <ListItemText primary={"Bodega"} primaryTypographyProps={{variant: "body2", color: "GrayText"}}/>
                                                    </ListItemButton>
                                                </ListItem>
                                            
                                        </Grid>
                                    </Grid>
                                </ListItemButton>
                            </ListItem>
                        </List>
                    </Collapse>
                    </>

                }
                {isSupervisor &&
                    <>
                    <ListItem disablePadding>
                        <ListItemButton onClick={handleExpand} sx={{justifyContent: "end"}}>   
                            <ListItemText primary={"Supervisor"}/>
                            {openAdmin ? <ExpandLess /> : <ExpandMore />}   
                        </ListItemButton>
                    </ListItem>
                    <Collapse in={openAdmin} timeout="auto">
                        <List>
                            <ListItem disablePadding>
                                <ListItemButton onClick={handleExpandWarehouse} sx={{justifyContent: "center"}}>
                                    <Grid container direction="column">
                                        <Grid item xs={12} sm={12} md={12} lg={12}>
                                                <ListItem disablePadding>
                                                    <ListItemButton onClick={() => createQrLabel("one")} sx={{justifyContent: "center"}}>
                                                        <ListItemText primary={"Creación QR"} primaryTypographyProps={{variant: "body2", color: "GrayText"}}/>
                                                    </ListItemButton>
                                                </ListItem>
                                                <ListItem disablePadding>
                                                    <ListItemButton onClick={() => gotToCheckpoint("one")} sx={{justifyContent: "center"}}>
                                                        <ListItemText primary={"Checkpoint"} primaryTypographyProps={{variant: "body2", color: "GrayText"}}/>
                                                    </ListItemButton>
                                                </ListItem>
                                                <ListItem disablePadding>
                                                    <ListItemButton onClick={() => gotToWarehouse("one")} sx={{justifyContent: "center"}}>
                                                        <ListItemText primary={"Bodega"} primaryTypographyProps={{variant: "body2", color: "GrayText"}}/>
                                                    </ListItemButton>
                                                </ListItem>
                                                <ListItem disablePadding>
                                                    <ListItemButton onClick={() => gotTobatchChanges()} sx={{justifyContent: "center"}}>
                                                        <ListItemText primary={"Cambio Estiba"} primaryTypographyProps={{variant: "body2", color: "GrayText"}}/>
                                                    </ListItemButton>
                                                </ListItem>
                                                <ListItem disablePadding>
                                                    <ListItemButton onClick={() => goToPendingOrders()} sx={{justifyContent: "center"}}>
                                                        <ListItemText primary={"Órdenes Pendientes"} primaryTypographyProps={{variant: "body2", color: "GrayText"}}/>
                                                    </ListItemButton>
                                                </ListItem>
                                                <ListItem disablePadding>
                                                    <ListItemButton onClick={() => goToTransportOrders()} sx={{justifyContent: "center"}}>
                                                        <ListItemText primary={"Órdenes Despacho"} primaryTypographyProps={{variant: "body2", color: "GrayText"}}/>
                                                    </ListItemButton>
                                                </ListItem>
                                                <ListItem disablePadding>
                                                    <ListItemButton onClick={() => goToCediOrders()} sx={{justifyContent: "center"}}>
                                                        <ListItemText primary={"Órdenes Cedi"} primaryTypographyProps={{variant: "body2", color: "GrayText"}}/>
                                                    </ListItemButton>
                                                </ListItem>
                                                {/* <ListItem disablePadding>
                                                    <ListItemButton onClick={() => goToNewCediOrder()} sx={{justifyContent: "center"}}>
                                                        <ListItemText primary={"Nueva orden Cedi"} primaryTypographyProps={{variant: "body2", color: "GrayText"}}/>
                                                    </ListItemButton>
                                                </ListItem> */}
                                                <ListItem disablePadding>
                                                    <ListItemButton onClick={goToCsvWarehouse} sx={{justifyContent: "center"}}>
                                                        <ListItemText primary={"Descarga Reportes"} primaryTypographyProps={{variant: "body2", color: "GrayText"}}/>
                                                    </ListItemButton>
                                                </ListItem>

                                        </Grid>
                                    </Grid>
                                </ListItemButton>
                            </ListItem>
                        </List>
                    </Collapse>
                    </>
                }

                {secondFactorEnabled === true &&
                <ListItem disablePadding>
                    <ListItemButton onClick={() => setF2Confirmation(true)} sx={{justifyContent: "end"}}>
                        <ListItemText primary={"Seguridad"} />
                    </ListItemButton>
                </ListItem>
                }
            </List>
        );
    };

    

    const handleExpand = () => {
        openAdmin=!openAdmin;
        handleAdmin(); 
    };

    const handleExpandQrys = () => {
        openQrys=!openQrys;
        handleAdmin(); 
    };

    const handleExpandSettings = () => {
        openSettings=!openSettings;
        handleAdmin(); 
    };
    
    const handleExpandDeals = () => {
        openDeals=!openDeals;
        handleAdmin(); 
    };

    const handleExpandDeliveries = () => {
        openDeliveries=!openDeliveries;
        handleAdmin(); 
    };
    
    const handleExpandMarketPlace = () => {
        openMeketPlace=!openMeketPlace;
        handleAdmin(); 
    };

    const handleExpandWarehouse = () => {
        openWarehouse=!openWarehouse;
        handleAdmin(); 
    };

    const handleSecurity = () => {
        const secondFactorRequest = {
            username: loginUser.accountId,
        }

        changeSecondFactor(secondFactorRequest, loginUser.jwtToken).then((secondFactorResult) => {
            if(!secondFactorResult){
                swal({
                    title: "Factor adicional deshabilitado",
                    text: `\n Desahabilitaste uno de los factores de autenticación`,
                    icon: "success",
                    button: "Entendido",
                }).then(()=>{
                    handleChooseProductClose();
                    loadProductsAndSetting("/credit/handle_credit", loginUser.accountId, loginUser.jwtToken).then((loadedInfo) => {
                      if(loadedInfo.visitorLoans){
                        history.push({pathname: "/credit/handle_credit"});
                      }
                
                      else{
                        history.push({pathname: "/account/create_credit"});
                      }
                        
                    });
                });
            }else{
                history.push({
                    pathname: "/totpcode",
                    state: {
                      qrImg: secondFactorResult
                    }
                });
            }
        })
    }
    
    const handleData = () => {
        history.push({
            pathname: "/client-profile"
        });
    }
    
    const [chooseProduct, setChooseProduct] = React.useState(false);

    const handleChooseProduct = () => {
      setChooseProduct(true);
    }
  
    const handleChooseProductClose = () => {
      setChooseProduct(false);
      loadProductsAndSetting("/credit/handle_credit", loginUser.accountId, loginUser.jwtToken).then((loadedInfo) => {
        if(loadedInfo.visitorLoans){
          history.push({pathname: "/credit/handle_credit"});
        }
  
        else{
          history.push({pathname: "/account/create_credit"});
        }
          
      });
    }  

    const[isAdmin, setIsAdmin] = React.useState(false);
    
    const handleAdmin = () => {

        if(loginUser.roles && !(Object.keys(loginUser.roles).length === 0 && loginUser.roles.constructor === Object)){
            
            if(loginUser.roles.some((rol) => rol.authority === "ADMIN")){
                setIsAdmin(true);
                profileOptionsDrawer(true);
            }

            else if(loginUser.roles.some((rol) => rol.authority === "PROD")){
                isProd=true;
                profileOptionsDrawer(false);
            }
            else if(loginUser.roles.some((rol) => rol.authority === "SUPERVISOR")){
                isSupervisor=true;
                profileOptionsDrawer(false);
            }
            else if(loginUser.roles.some((rol) => rol.authority === "TRANSPORTER")){
                isTransporter=true;
                profileOptionsDrawer(false);
            }

            else {
                profileOptionsDrawer(false);
            }
        }

    };

    const goAdminQrys = () =>{
        history.push({pathname: "/admin-rol"});
    };

    const goAdminSettings = () =>{
        history.push({pathname: "/admin-settings"});
    };

    const goAdminKpi = () =>{
        history.push({pathname: "/admin-kpi"});
    };

    const[adminChoose, setAdminChoose] = React.useState(false);

    const handleChooseAdminClose = () =>{
        setAdminChoose(false)
    };

    const chooseAdminRequest = () =>{
        setAdminChoose(true);
    };

    //second factor warning
    const[f2Text, setF2Text] = React.useState('');
    const[f2Confirmation, setF2Confirmation] = React.useState(false);
    const[f2ButtonDisabled, setF2ButtonDisabled] = React.useState(true);

    const F2ConfirmationClose = () => {
        setF2Confirmation(false);
    };

    useEffect(() => {
        if(f2Text === "Acepto"){
            setF2ButtonDisabled(false);
        }else if(!f2ButtonDisabled){
            setF2ButtonDisabled(true);
        }
    }, [f2Text])

    const exitSystem = () => {
        history.push({pathname: "/"});
    }

    const [mobileOpen, setMobileOpen] = React.useState(false);

    const handleDrawerToggle = () => {
      setMobileOpen(!mobileOpen);
    };

  return (
      <React.Fragment>
        {loading ? <PuffLoader size={100} color={Colors.secondary} css={cssLoader} /> :
            <Grid container>
                <Grid item xs={2} sm={4} md={3} lg={3} sx={{paddingLeft: "1rem"}}>
                    <Box display={{ xs: "flex", sm: "flex", md: "none", lg: "none"}} sx={{justifyContent: {xs: "end", sm: "center"}, width: "100%"}}>
                        <IconButton
                            color="inherit"
                            //   edge="start"
                            onClick={handleDrawerToggle}
                            sx={{ mr: 2, display: { sm: 'flex',  md: "none"} }}
                            size="large">
                            <MenuIcon />
                        </IconButton>
                    </Box>
                    <Drawer
                        // container={container}
                        variant="temporary"
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}
                        sx={{
                            display: { xs: 'block', sm: 'block', md: "none" },
                            '& .MuiDrawer-paper': { boxSizing: 'border-box'},
                        }}
                        PaperProps={{
                            sx:{
                                marginTop: "5rem",
                                width: {sx: "50%", sm: "35%"},
                            }
                        }}
                        >
                        {profileOptions}
                    </Drawer>
                    <Box sx={{marginTop: "5rem"}}>
                        <Drawer
                            variant="permanent"
                            sx={{
                                display: { xs: 'none', sm: 'none', md: 'flex'},
                                '& .MuiDrawer-paper': { boxSizing: 'border-box'}
                            
                            }}
                            PaperProps={{
                                sx:{
                                    maxHeight: "40rem",
                                    marginTop: "5rem",
                                    width: "20%",
                                    overflowY: 'auto' 
                                    // paddingLeft: "0.6rem"
                                }
                            }}
                            open
                            
                            >
                            {profileOptions}
                        </Drawer>
                    </Box>
                </Grid>
                {nutrion === false ?
                <Grid item xs={10} sm={8} md={9} lg={9}>
                    <ClientRol {...props}/>
                </Grid>
                :
                <Grid tem xs={10} sm={8} md={9} lg={9} sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                    <Box 
                        component="img"
                        sx={{
                        height: {xs: "20%", sm: "30%", md: "24%"},
                        // maxHeight: "200px",
                        width: {xs: "45%", sm: "20%", md: "14%"},
                        justifyContent: "center",
                        }}
                        alt="Foto"
                        src="./images/logo-nutrion.png"

                    >
                    </Box>
                </Grid>
                }
            </Grid>
        }
          <Dialog open={chooseProduct} onClose={handleChooseProductClose} aria-labelledby="form-dialog-products">
              <DialogTitle style={{color: "blue"}}>TU PERFIL</DialogTitle>
              
              <DialogContent className={classes.actionArea}>
                  <Card sx={{ minWidth: 150 }}>
                      <CardActionArea onClick={handleData}>
                          <BsFillPersonFill size={20} />
                          <CardContent>
                              <Typography variant="h6" component="div">
                                  PERFIL
                              </Typography>
                          </CardContent>
                      </CardActionArea>
                  </Card>
              </DialogContent>

              <DialogContent className={classes.actionArea}>
                  <Card sx={{ minWidth: 150 }}>
                      <CardActionArea onClick={() => setF2Confirmation(true)}>
                          <AiFillLock size={20} />
                          <CardContent>
                              <Typography variant="h6" component="div">
                                  CAMBIA ESTADO SEGUNDO FACTOR
                              </Typography>
                          </CardContent>
                      </CardActionArea>
                  </Card>
              </DialogContent>

              {isAdmin ?
                  <DialogContent className={classes.actionArea}>
                      <Card sx={{ minWidth: 150 }}>
                          <CardActionArea onClick={chooseAdminRequest}>
                              <RiAdminLine size={20} />
                              <CardContent>
                                  <Typography variant="h6" component="div">
                                      ADMINISTRADOR
                                  </Typography>
                              </CardContent>
                          </CardActionArea>
                      </Card>
                  </DialogContent>
              : <></>}
          </Dialog>
          <Dialog open={adminChoose} onClose={handleChooseAdminClose}>
              <DialogTitle style={{color: "blue"}}>TIPO DE OPERACIÓN</DialogTitle>
              <DialogContent className={classes.actionArea}>
                  <Card sx={{ minWidth: 150 }}>
                      <CardActionArea onClick={goAdminQrys}>
                          <FaSearch size={20} />
                          <CardContent>
                              <Typography variant="h5" component="div">
                                  CONSULTAS
                              </Typography>
                          </CardContent>
                      </CardActionArea>
                  </Card>
              </DialogContent>

              <DialogContent className={classes.actionArea}>
                  <Card sx={{ minWidth: 150 }}>
                      <CardActionArea onClick={goAdminSettings}>
                          <IoSettings size={20} />
                          <CardContent>
                              <Typography variant="h5" component="div">
                                  CONFIGURACIÓN
                              </Typography>
                          </CardContent>
                      </CardActionArea>
                  </Card>
              </DialogContent>

              <DialogContent className={classes.actionArea}>
                  <Card sx={{ minWidth: 150 }}>
                      <CardActionArea onClick={goAdminKpi}>
                          <FaChartLine size={20} />
                          <CardContent>
                              <Typography variant="h5" component="div">
                                  KPI
                              </Typography>
                          </CardContent>
                      </CardActionArea>
                  </Card>
              </DialogContent>
          </Dialog>
          <Dialog open={f2Confirmation} onClose={F2ConfirmationClose}>
          <DialogTitle style={{color: "red"}}>CAMBIO FACTOR DE AUTENTICACIÓN</DialogTitle>
          <DialogContent>
              <Typography variant="body" style={{whiteSpace: 'pre-line', color: "GrayText"}} >
                  {F2Explanation}
                  </Typography>
                  <TextField
                  style={{marginTop: "1rem"}}
                  id="F2Acceptance"
                  name="F2Acceptance"
                  // label="Nombres"
                  fullWidth
                  // autoComplete="given-name"
                  onChange={(e) => setF2Text(e.target.value)}
                  value={f2Text}
              />
          </DialogContent>
          <DialogActions>
              <Button onClick={F2ConfirmationClose} sx={{color: "grey"}}>
                  ATRÁS
              </Button>
              <Button 
                  onClick={handleSecurity} sx={{color: Colors.primary}}
                  disabled={f2ButtonDisabled}>
                  CONTINUAR
              </Button>
          </DialogActions>
          </Dialog>

       </React.Fragment>
  );
}
